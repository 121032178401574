import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Code snippet" component="code-snippet" variation="code-snippet" hasReactVersion hasVueVersion="codesnippet--oneline" codepen="vMLEbR" hasAngularVersion="?path=/story/codesnippet--basic" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Inline code snippet" component="code-snippet" variation="code-snippet--inline" hasLightVersion hasReactVersion hasVueVersion="codesnippet--inline" hasAngularVersion="?path=/story/codesnippet--inline" codepen="LvGVYe" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Multi line code snippet" component="code-snippet" variation="code-snippet--multi" hasReactVersion hasVueVersion="codesnippet--multiline" hasAngularVersion="?path=/story/codesnippet--multi" codepen="ZZQGGO" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="code-snippet" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      